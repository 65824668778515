import React from 'react'; 
import { CgCPlusPlus } from "react-icons/cg";
import {FaReact, FaJava,  FaAngular, FaCss3, FaHtml5, FaBootstrap} from "react-icons/fa";
import {DiNodejs, DiJavascript1} from "react-icons/di";
import {SiWordpress, SiMysql, SiShopify,SiTypescript,SiNextdotjs} from "react-icons/si";

const Skills = ({skill}) => {
    const icon = {
        Nextjs: <SiNextdotjs/>,
        Java: <FaJava/>,
        React: <FaReact/>,
        Javascript: <DiJavascript1/>,
        Node : <DiNodejs/>,
        Wordpress : <SiWordpress/>,
        Mysql : <SiMysql/>,
        Typescript : <SiTypescript/>,
        Angular : <FaAngular/>,
        Css : <FaCss3/>,
        Html5 : <FaHtml5/>,
        Bootstrap: <FaBootstrap/>,
        Shopify : <SiShopify/>
    }
    
  return (
    <div title={skill} className='SkillBox'>
      {icon[skill]}
    </div>
  )
}

export default Skills
