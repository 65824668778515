import React from 'react';
import Lottie from  "lottie-react";
import SpaceBoy from "../LottieFiles/SpaceBoy.json";
import Spacefull from "../LottieFiles/space man full.json";
import Typed from "./Typed.js";
import {GoRocket} from "react-icons/go";

const Home = () => {
  return (
    <div >
      <div className='HomePage'>

        <div className='HomeText'>
          <h1>Hi There!</h1>
          <h1>I'M <b>Shivam Joshi</b></h1>
          <Typed/>   
        </div>

        <Lottie 
          className="illustration" 
          animationData={Spacefull} 
          loop={true} 
        />
        
      </div>

      <div className='AboutPage'>
        <div className='AboutText'>
          <h1 className='AboutTextHeading'>Welcome To My <b>Digital Universe! </b></h1>
          <p>
          By day, I'm a <b>coding wizard </b>, crafting digital dreams for clients around the globe. By night, I'm a <b>stargazer </b>, reaching for the stars and dreaming of galaxies far, far away. <br /><br />
          I build websites that are out of this world, and I've got the skills to prove it.  Whether you're looking for a website that's as sleek as a spaceship or as dynamic as a supernova, <b>I'm your guy </b>.<br /> <br />
          So, <b>grab your space helmet </b>, buckle up, and let's embark on a cosmic journey through the digital cosmos together.  <GoRocket style={{scale:"1.5", rotate:"15deg"}}/>   
          </p>
        </div>
        <Lottie 
          className="illustration" 
          animationData={SpaceBoy} 
          loop={true} 
        />
      </div>
    </div>
  )
}

export default Home