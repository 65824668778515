import React from 'react';
import {FaGithub, FaLinkedin} from "react-icons/fa";
import {GrMail} from "react-icons/gr";
import {SiInstagram} from "react-icons/si"

const Footer = () => {
  return (
    <footer>
      <h4>Developed by Shivam joshi</h4>
      <h4>Copyright &copy; 2023 SJ</h4>
      <div className='footerLinks'>
        <a href="https://github.com/Shivamj0shi" target='_blank'><FaGithub/></a>
        <a href="https://www.linkedin.com/in/shivam-j0shi/" target='_blank'><FaLinkedin/></a>
        <a href='mailTo:joshishivam296@gmail.com' target='_blank'><GrMail/></a>
        <a href="https://www.instagram.com/shivamj0shi/" target="_blank"><SiInstagram/></a>
      </div>
    </footer>
  )
}

export default Footer