import React from 'react';
import Skills from './Skills.js';
import Tilt from 'react-parallax-tilt';
import Shivam from '../assets/Shivamjoshi.jpg'
const About = () => {
  return (
    <>
      <div className='AboutPage'>
        <div className='AboutText' id='about-text'>
          <h1 className='AboutTextHeading' >Get to <b>know</b> me!</h1>
          <p>
            Hi, this is <b> Shivam Joshi </b>, an experienced <b>full stack website developer</b> who is passionate about crafting tailored online experiences for clients. Armed with a skill set that encompasses <b>frontend and backend</b>, I possess the tools to transform any website vision into reality. <br/><br/>
            However, my expertise goes beyond just custom development. I'm well-versed in utilizing CMS systems such as <b>WordPress and Shopify</b>. This empowers my clients to effortlessly manage and update their websites, ensuring they remain current and engaging.<br/><br/>
            My portfolio extends to include successful projects for numerous clients. I operate as a professional web developer. Additionally, I'm actively <b>engaged in freelancing</b>, further honing my skills and expanding my creative horizons.<br/>
            
          </p>
        </div>

        <div className='about-img' >
          <Tilt>
             <img src={Shivam} alt='Shivamjoshi'></img>
          </Tilt>
        </div>

      </div>
      
      <h1 className='SkillsHeading'>Professional Skillset</h1>
      <div className='skills'>
        
        
        <Skills skill='Html5' />
        <Skills skill='Css' />
        <Skills skill='Javascript' />
        <Skills skill='Bootstrap'/>
        <Skills skill='React' />
        <Skills skill='Angular' />
        <Skills skill='Java' />
        <Skills skill='Shopify' />
        <Skills skill='Wordpress' />
        <Skills skill='Node' />
        <Skills skill='Mysql' />
        <Skills skill='Typescript' />
        <Skills skill='Nextjs' />
      </div>
    </>
  )
}

export default About