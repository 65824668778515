import React from 'react';
import {FaGithub} from "react-icons/fa";
import {GiPlayButton} from "react-icons/gi";


const  ProjectBox = ({projectPhoto, projectName}) => {
  const desc = {
    SippiDesc : " Sippi is a captivating e-commerce website designed and developed using Shopify and Liquid programming. This dynamic platform offers an exquisite collection of fashionable clothing for men and women, with a special focus on women's apparel. Sippi provides a seamless shopping experience, showcasing trendy outfits that cater to diverse tastes and preferences.",
    SippiWebsite : "https://sippi-in.myshopify.com/",
    SippiGithub:"",

    NamamiDigitalDesc : "  Namami Digital is a dynamic news website built using WordPress. It serves as a reliable and interactive platform for publishing news articles and keeping users informed about the latest happenings. The website is designed to provide a seamless reading experience with a user-friendly interface and intuitive navigation.",
    NamamiDigitalGithub : "",
    NamamiDigitalWebsite : "https://namamidigital.com/",

    ForceToolsDesc:"This is an international brand of mechanical tools with a website designed to showcase its wide range of products. Visitors to the website can browse through the different types of tools available, gaining valuable information about each product. If they decide to make a purchase, they can find a contact form or contact details on the product page, allowing them to get in touch with the retailer directly. ",
    ForceToolsGithub:"",
    ForceToolsWebsite:"https://forcetools.co.in/",

    PortfolioDesc : " The website features a clean and intuitive interface, allowing visitors to easily navigate through my projects, skills, and achievements. The use of React enables dynamic content rendering and component-based architecture, enhancing the website's performance and scalability.",
    PortfolioWebsite : "https://shivamjoshi.in/",
    PortfolioGithub:"",

    SleepinplushDesc : "  This is an exquisite online platform that offers a wide range of premium sleepwear and loungewear for both men and women.One of the key features of the website is its personalized recommendation system. By analyzing customer preferences and browsing history, Sleepinplush suggests tailored product recommendations, making the shopping experience more convenient and enjoyable.",
    SleepinplushGithub : "",
    SleepinplushWebsite : "https://sleepinplush.com/",

    ShivpsyDesc:"Shivpsy is a prototype of an e-commerce website developed using HTML, CSS, JavaScript, and Bootstrap . The website incorporates modern design elements and responsive layouts to ensure compatibility across different devices and screen sizes. It emphasizes clean code and effective validation techniques to enhance the overall functionality and user experience.",
    ShivpsyGithub:"",
    ShivpsyWebsite:"https://shivamj0shi.github.io/Shivpsy/"
  }

  let show ='';
  if(desc[projectName + 'Github']===""){
    show="none";
  }
    
  return (
    <div className='projectBox'> 
        <img className='projectPhoto' src={projectPhoto} alt="Project display" /> 
        <div>
            <br />
            <h3>{projectName}</h3>
            <br />
            {desc[projectName + 'Desc']}
            <br />

            <a style={{display:show}} href={desc[projectName + 'Github']} target='_blank'>
              <button className='projectbtn'><FaGithub/> Github</button>
            </a>

            <a href={desc[projectName + 'Website']} target='_blank'>
              <button className='projectbtn'><GiPlayButton/> View</button>
            </a>
        </div>
    </div>
  )
}

export default  ProjectBox